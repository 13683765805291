import React from "react";
import { useRoutes } from "react-router-dom";
import About from "../About";
import Contact from "../Contact";
import Docs from "../Docs";
import First from "../First";
import Home from "../Home";
import NotFound from "../NotFound";

const AllRoutes = () => {
  let elements = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/docs",

      children: [
        {
          index: true,
          element: <Docs />,
        },
        {
          path: "firstRoute",
          element: <First />,
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return elements;
};

export default AllRoutes;
