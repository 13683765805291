import React from "react";
import styled from "styled-components";
import pic from "./ASSESTS/island2.png";
import { NavLink } from "react-router-dom";
const NotFound = () => {
  return (
    <div>
      <Container>
        <Holder>
          <DivOne>
            <h3>OOPPS!!</h3>
            <h1>404</h1>
            <pre>
              Sorry , the page you are looking <br /> for could not be found
            </pre>
            <BtnHold>
              <NavLink to="/">
                <Button>Take Me Back</Button>
              </NavLink>
            </BtnHold>
          </DivOne>
          <DivTwo>
            <Img src={pic} />
          </DivTwo>
        </Holder>
      </Container>
    </div>
  );
};

export default NotFound;

const Container = styled.div``;

const Holder = styled.div`
  width: 100%;
  height: 100vh;
  /* background-color: plum; */
  /* flex-direction: column; */
  display: flex;
  background-color: rgb(1, 0, 189);
  background: linear-gradient(
    90deg,
    rgba(1, 0, 189, 1) 0%,
    rgba(11, 10, 180, 1) 50%,
    rgba(101, 1, 255, 0.6542669584245077) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
`;

const DivOne = styled.div`
  width: 40%;
  height: 100%;

  h1 {
    font-size: 60px;
    text-decoration: underline;
    color: white;
    font-weight: bold;
    margin-left: 70px;
  }

  pre {
    font-size: 25px;
    font-weight: 500;
    color: white;
    margin-left: 70px;
    margin-bottom: 50px;
  }
  h3 {
    font-size: 25px;
    font-weight: 500;
    color: white;
    margin-left: 70px;
    margin-bottom: 50px;
  }
  /* background-color: black; */
`;

const DivTwo = styled.div`
  width: 60%;
  height: 100%;
  /* background-color: blue; */
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BtnHold = styled.div``;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 70px;
  font-size: 15px;
  font-weight: 500;
  color: white;
  width: 250px;
  height: 50px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 30px;
  transition: all ease-in-out 350ms;
  cursor: pointer;

  :hover {
    transform: scale(0.97);
    background-color: rgb(1, 0, 189);
    background: linear-gradient(
      90deg,
      rgba(1, 0, 189, 1) 0%,
      rgba(101, 1, 255, 0.6542669584245077) 100%,
      rgba(11, 10, 180, 1) 50%
    );
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }
`;

{
  /* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path
    fill="#13171A"
    fill-opacity="1"
    d="M0,96L26.7,117.3C53.3,139,107,181,160,170.7C213.3,160,267,96,320,96C373.3,96,427,160,480,186.7C533.3,213,587,203,640,186.7C693.3,171,747,149,800,149.3C853.3,149,907,171,960,165.3C1013.3,160,1067,128,1120,117.3C1173.3,107,1227,117,1280,117.3C1333.3,117,1387,107,1413,101.3L1440,96L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
  ></path>
</svg>; */
}
