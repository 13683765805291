import React from "react";
import styled from "styled-components";

const Home = () => {
  return (
    <div>
      <Container>
        <Tex>HOMEPAGE</Tex>
      </Container>
    </div>
  );
};

export default Home;

const Tex = styled.div`
  font-size: 40px;
  text-align: center;
  color: white;
  font-weight: bold;
`;
const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(1, 0, 189);
  background: linear-gradient(
    90deg,
    rgba(1, 0, 189, 1) 0%,
    rgba(101, 1, 255, 0.6542669584245077) 100%,
    rgba(11, 10, 180, 1) 50%
  );
`;
