import React from "react";
import { NavLink } from "react-router-dom";

const Docs = () => {
  return (
    <div>
      <NavLink to="/docs/firstRoute">First Route</NavLink>
    </div>
  );
};

export default Docs;
