import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
const Header = () => {
  return (
    <Hold>
      <NavLink to="/">
        <div>Home</div>
      </NavLink>
      <NavLink to="/about">
        <div>About</div>
      </NavLink>
      <NavLink to="/contact">
        <div>Contact</div>
      </NavLink>
      <NavLink to="/docs">
        <div>docs</div>
      </NavLink>
      <a href="http://google.com/">visit link</a>
    </Hold>
  );
};

export default Header;

const Hold = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  background-color: white;
  text-decoration: none;
  color: black;

  font-weight: bold;
  font-size: 20px;
`;
